<template>
  <div id="downloads">
    <div class="content-box">
      <div class="inner">
        <h3>{{ $t('menu.download') }}</h3>

        <div class="card">
          <div class="meta-box">
            <ul class="tab-demo">
              <li :class="{ active: activeName == 'mt4' }" @click="activeName = 'mt4'" data-testid="mt4">
                METATRADER 4
              </li>
              <li :class="{ active: activeName == 'mt5' }" @click="activeName = 'mt5'" data-testid="mt5">
                METATRADER 5
              </li>
            </ul>
            <div v-show="activeName == 'mt4'">
              <div class="box" v-if="$config.getTradingPlatform(regulator).mt4">
                <ul>
                  <li>
                    <div class="title">{{ $t('downloads.windows') }}</div>
                    <a :href="urls.mt4.METATRADER4_windows" data-testid="windows4">
                      <div class="clickable">
                        <div class="picture windows"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">Mac OS</div>
                    <a :href="urls.mt4.METATRADER4_iOS" data-testid="mac4">
                      <div class="clickable">
                        <div class="picture mac"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">Linux</div>
                    <a :href="urls.mt4.METATRADER4_windows" data-testid="linux4">
                      <div class="clickable">
                        <div class="picture linux"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">{{ $t('downloads.ip') }}</div>
                    <a :href="urls.mt4.METATRADER4_iPhone" data-testid="iphone4">
                      <div class="clickable">
                        <div class="picture iphone"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">{{ $t('downloads.android') }}</div>
                    <a :href="urls.mt4.METATRADER4_Android" data-testid="android4">
                      <div class="clickable">
                        <div class="picture android"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">{{ $t('downloads.web') }}</div>
                    <a :href="urls.mt4.METATRADER4_WebTrader" data-testid="web4">
                      <div class="clickable">
                        <div class="picture webtrader"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div v-show="activeName == 'mt5'">
              <div class="box" v-if="$config.getTradingPlatform(regulator).mt5">
                <ul>
                  <li>
                    <div class="title">{{ $t('downloads.windows') }}</div>
                    <a :href="urls.mt5.METATRADER5_windows" data-testid="windows5">
                      <div class="clickable">
                        <div class="picture windows"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">Mac OS</div>
                    <a :href="urls.mt5.METATRADER5_iOS" data-testid="mac5">
                      <div class="clickable">
                        <div class="picture mac"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">Linux</div>
                    <a :href="urls.mt5.METATRADER5_windows" data-testid="linux5">
                      <div class="clickable">
                        <div class="picture linux"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">{{ $t('downloads.ip') }}</div>
                    <a :href="urls.mt5.METATRADER5_iPhone" data-testid="iphone5">
                      <div class="clickable">
                        <div class="picture iphone"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">{{ $t('downloads.android') }}</div>
                    <a :href="urls.mt5.METATRADER5_Android" data-testid="android5">
                      <div class="clickable">
                        <div class="picture android"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="title">{{ $t('downloads.web') }}</div>
                    <a :href="urls.mt5.METATRADER5_WebTrader" data-testid="web5">
                      <div class="clickable">
                        <div class="picture webtrader"></div>
                        <div class="download-btn"></div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <h3>{{ $t('downloads.app') }}</h3>

        <div class="card app">
          <img src="@/assets/images/download/app.png" class="appImg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'downloads',
  data() {
    return {
      activeName: 'mt4'
    };
  },
  computed: {
    urls() {
      return this.$config.getMetaTraderLinks(this.regulator, this.GLOBAL_DOMAIN_WEBSITE);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/downloads.scss';
</style>
